import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import './../assets/css/form_sante.css'

const FormSante = () => {
  const [formToShow, setFormToShow] = useState('step1')

  const [dataForm, setDataForm] = useState({
    prenom: '',
    nom: '',
    fonction: '',
    email: '',
    telephone: '',
    nomDeLetaSociete: '',
    adresse: '',
    codePostal: '',
    ville: ''
  })

  const [dataFormErr, setDataFormErr] = useState({
    nom: false,
    prenom: false,
    nomDeLetaSociete: false,
    fonction: false,
    telephone: false,
    adresse: false,
    codePostal: false,
    email: false,
    ville: false
  })

  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    const { value, name } = e.target
    console.log(dataForm)
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const validateData = () => {
    const { nom, prenom, email, nomDeLetaSociete, telephone, fonction, adresse, ville, codePostal } = dataForm

    function validateEmail (mail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(mail).toLowerCase())
    }

    setDataFormErr({
      nom: nom.length === 0,
      prenom: prenom.length === 0,
      email: !validateEmail(email),
      adresse: adresse.length === 0,
      ville: ville.length === 0,
      codePostal: codePostal.length === 0,
      nomDeLetaSociete: nomDeLetaSociete.length === 0,
      telephone: telephone.length === 0,
      fonction: fonction.length === 0
    })
    console.log(dataFormErr)

    if (nom.length === 0) return false
    else if (!validateEmail(email)) return false
    else if (prenom.length === 0) return false
    else if (nomDeLetaSociete.length === 0) return false
    else if (telephone.length === 0) return false
    else if (fonction.length === 0) return false
    else if (adresse.length === 0) return false
    else if (ville.length === 0) return false
    else if (codePostal.length === 0) return false
    else return true
  }

  const validatePageOneData = () => {
    const { nom, prenom, email, telephone, fonction } = dataForm

    function validateEmail (mail) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(mail).toLowerCase())
    }

    setDataFormErr({
      nom: nom.length === 0,
      prenom: prenom.length === 0,
      email: !validateEmail(email),
      telephone: telephone.length === 0,
      fonction: fonction.length === 0
    })
    console.log(dataFormErr)

    if (nom.length === 0) return false
    else if (!validateEmail(email)) return false
    else if (prenom.length === 0) return false
    else if (telephone.length === 0) return false
    else if (fonction.length === 0) return false
    else return setFormToShow('step2')
  }

  const sendMsg = e => {
    const isValid = validateData()
    console.log('send')
    e.preventDefault()

    // const { nom, prenom, email, nomDeLetaSociete, telephone, fonction, adresse, ville, codePostal } = dataForm

    console.log(isValid)

    if (isValid) {
      // const payload = { nom, email, prenom, telephone, fonction, nomDeLetaSociete, adresse, ville, codePostal }
      console.log('good')
      fetch('https://usebasin.com/f/fe91c6200637', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataForm)
      })
        .then(res => {
          // console.log('sended', res.ok)
          if (res.ok) {
            setSuccess(true)
            setDataForm({
              nom: '',
              prenom: '',
              fonction: '',
              email: '',
              telephone: '',
              nomDeLetaSociete: '',
              adresse: '',
              codePostal: '',
              ville: ''
            })
            setTimeout(() => setSuccess(false), 5000)
            setTimeout(() => setFormToShow('step1'), 5000)
          }
        })
        .catch(err => {
          // console.log("not send", err);
        })
    }
  }

  return (
    <>
      <Helmet>
        <title>Santé | Paramedic</title>
      </Helmet>
      <section className='form-box'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 form-wizard'>
              <form onSubmit={sendMsg}>
                {/* Form Step 1 */}
                <fieldset className={formToShow === 'step1' ? 'fade-in' : 'fade-out show_form'}>
                  <h3>Pour commencer</h3>
                  <p>Apprenons à se connaitre !</p>
                  <div className='form-group'>
                    <label>Prénom*</label>
                    <input value={dataForm.prenom} onChange={e => handleChange(e)} type='text' name='prenom' className={`form-control ${dataFormErr.prenom ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Nom*</label>
                    <input value={dataForm.nom} onChange={e => handleChange(e)} type='text' name='nom' className={`form-control ${dataFormErr.nom ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Fonction*</label>
                    <input value={dataForm.fonction} onChange={e => handleChange(e)} type='text' name='fonction' className={`form-control ${dataFormErr.fonction ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Adresse éléctronique*</label>
                    <input value={dataForm.email} onChange={e => handleChange(e)} type='text' name='email' className={`form-control ${dataFormErr.email ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Numéro de téléphone*</label>
                    <input value={dataForm.telephone} onChange={e => handleChange(e)} type='text' name='telephone' className={`form-control ${dataFormErr.telephone ? 'invalid' : ''}`} />
                  </div>

                  <div className='form-wizard-buttons'>
                    <button type='button' onClick={validatePageOneData} className='btn btn-next'>Prochaine étape</button>
                  </div>
                </fieldset>

                <fieldset className={formToShow !== 'step1' ? 'fade-in' : 'fade-out show_form'}>

                  <h3>Et le dernier pour la route !</h3>
                  <div className='form-group top_2nd_form'>
                    <label>Nom de la société*</label>
                    <input value={dataForm.nomDeLetaSociete} onChange={e => handleChange(e)} type='text' name='nomDeLetaSociete' className={`form-control ${dataFormErr.nomDeLetaSociete ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Adresse*</label>
                    <input value={dataForm.adresse} onChange={e => handleChange(e)} type='text' name='adresse' className={`form-control ${dataFormErr.adresse ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Code postal*</label>
                    <input value={dataForm.codePostal} onChange={e => handleChange(e)} type='text' name='codePostal' className={`form-control ${dataFormErr.codePostal ? 'invalid' : ''}`} />
                  </div>
                  <div className='form-group'>
                    <label>Ville*</label>
                    <input value={dataForm.ville} onChange={e => handleChange(e)} type='text' name='ville' className={`form-control ${dataFormErr.ville ? 'invalid' : ''}`} />
                  </div>

                  <div className='form-wizard-buttons'>
                    <button type='button' onClick={() => setFormToShow('step1')} className='btn mr-1 btn-previous'>Retour</button>
                    <button type='submit' className='btn btn-submit ml-1'>Soumettre</button>
                  </div>
                </fieldset>

                {/* Form Step 2 */}
                {dataFormErr.checked ||
                dataFormErr.telephone ||
                dataFormErr.nomDeLetablissement ||
                dataFormErr.nom ||
                dataFormErr.prenom ||
                dataFormErr.email ||
                dataFormErr.nomDeLetaSociete ||
                dataFormErr.fonction ||
                dataFormErr.adresse ||
                dataFormErr.codePostal ||
                dataFormErr.ville
                  ? (
                    <div style={{ height: '3em', marginTop: '1em' }}>
                      <p className='success' style={{ backgroundColor: 'red' }}>Veuillez remplir les champs indiquer en rouge</p>
                    </div>
                    )
                  : null}
                {success && (
                  <div style={{ height: '3em', marginTop: '1em' }}>
                    <p className='success'>Votre message a bien été envoyer</p>
                  </div>
                )}
              </form>
              {/* Form Wizard */}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default FormSante
